import type {SVGProps} from 'react';

// NOTE: For this icon to appear correctly in your component, you must also import and use `./GradientDefs`.

const Partner = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <path
      d="M39.5 20.25C39.5 30.8815 30.8815 39.5 20.25 39.5C9.61852 39.5 1 30.8815 1 20.25C1 9.61852 9.61852 1 20.25 1C30.8815 1 39.5 9.61852 39.5 20.25Z"
      stroke="#162120"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7704 11.4294C19.8572 11.2279 20.1428 11.2279 20.2296 11.4294L22.0505 15.6577C22.3038 16.246 22.8584 16.6489 23.4962 16.708L28.0802 17.1332C28.2986 17.1534 28.3869 17.4251 28.2221 17.5699L24.7634 20.6083C24.2822 21.031 24.0704 21.6829 24.2113 22.3078L25.2234 26.7988C25.2717 27.0128 25.0405 27.1807 24.8519 27.0687L20.8935 24.7183C20.3427 24.3912 19.6573 24.3912 19.1065 24.7183L15.1481 27.0687C14.9594 27.1807 14.7283 27.0128 14.7766 26.7988L15.7887 22.3078C15.9296 21.6829 15.7178 21.031 15.2365 20.6083L14.7416 21.1717L15.2365 20.6083L11.7779 17.5699C11.6131 17.4251 11.7014 17.1534 11.9198 17.1332L16.5038 16.708C17.1416 16.6489 17.6962 16.246 17.9495 15.6577L19.7704 11.4294Z"
      stroke="#162120"
      strokeWidth="1.5"
    />
    <path d="M20.9897 19H19V20.9897H20.9897V19Z" fill="#8EA7FF" />
  </svg>
);

export default Partner;
