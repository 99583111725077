import {cva} from 'class-variance-authority';

import {HeaderBackgroundColorScheme, ScrollDirection} from '@/enums';

export const headerStyles = cva('z-50 w-full', {
  variants: {
    isFixed: {
      true: 'fixed',
      false: 'absolute top-0',
    },
    hasSecondaryNav: {true: '', false: ''},
  },
  compoundVariants: [
    {
      isFixed: true,
      hasSecondaryNav: false,
      className: 'top-0',
    },
    {
      isFixed: true,
      hasSecondaryNav: true,
      className: 'top-[-72px]',
    },
  ],
});

export const headerContainerStyles = cva(
  'top-0 w-full z-50 will-change-auto duration-0',
  {
    variants: {
      backgroundColorScheme: {
        [HeaderBackgroundColorScheme.White]: 'bg-white text-black',
        [HeaderBackgroundColorScheme.Black]: 'bg-black text-white',
        [HeaderBackgroundColorScheme.TransparentLight]:
          'bg-transparent text-black',
        [HeaderBackgroundColorScheme.TransparentDark]:
          'bg-transparent text-white',
      },
      hasSecondaryNav: {true: '', false: ''},
      isDesktopMenuOpen: {true: '', false: ''},
      isFixed: {true: '', false: ''},
      isMobileMenuOpen: {true: '', false: ''},
      isScrolled: {true: '', false: ''},
      isEnterpriseBlog: {true: '', false: ''},
      isThresholdPassed: {true: '', false: ''},
      scrollDirection: {[ScrollDirection.Up]: '', [ScrollDirection.Down]: ''},
    },
    compoundVariants: [
      {
        backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
        isDesktopMenuOpen: false,
        isFixed: true,
        isScrolled: true,
        className: 'bg-white',
      },
      {
        backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
        isDesktopMenuOpen: true,
        className: 'bg-white',
      },
      {
        backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
        isMobileMenuOpen: true,
        className: 'bg-white',
      },
      {
        backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
        isDesktopMenuOpen: false,
        isFixed: true,
        isScrolled: true,
        className: 'bg-white',
      },
      {
        backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
        isDesktopMenuOpen: true,
        className: 'bg-white',
      },
      {
        backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
        isMobileMenuOpen: true,
        className: 'bg-white',
      },
      {
        isFixed: true,
        hasSecondaryNav: true,
        scrollDirection: ScrollDirection.Up,
        className: 'translate-y-[72px] transition-all',
      },
      {
        isThresholdPassed: true,
        isEnterpriseBlog: false,
        className: 'translate-y-0 transition-colors',
      },
    ],
  },
);

export const headerContainer2Styles = cva('transition-colors duration-700', {
  variants: {
    backgroundColorScheme: {
      [HeaderBackgroundColorScheme.White]: 'border-b border-black/20',
      [HeaderBackgroundColorScheme.Black]: 'border-b border-white/20',
      [HeaderBackgroundColorScheme.TransparentLight]: '',
      [HeaderBackgroundColorScheme.TransparentDark]: '',
    },
    hasSecondaryNav: {true: '', false: ''},
    isDesktopMenuOpen: {true: '', false: ''},
    isFixed: {true: '', false: ''},
    isMobileMenuOpen: {true: '', false: ''},
  },
  compoundVariants: [
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
      isFixed: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
      isDesktopMenuOpen: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
      hasSecondaryNav: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
      isFixed: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
      isDesktopMenuOpen: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
      hasSecondaryNav: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme:
        HeaderBackgroundColorScheme.White ||
        HeaderBackgroundColorScheme.TransparentLight,
      isMobileMenuOpen: true,
      className: 'bg-white',
    },
    {
      backgroundColorScheme:
        HeaderBackgroundColorScheme.Black ||
        HeaderBackgroundColorScheme.TransparentDark,
      isMobileMenuOpen: true,
      className: 'bg-black',
    },
  ],
});

export const primaryNavigationListStyles = cva('lg:hidden flex items-center', {
  variants: {
    hasHamburger: {
      true: 'mr-[-15px]',
      false: '',
    },
  },
});

export const secondaryNavigationContainerStyles = cva('', {
  variants: {
    backgroundColorScheme: {
      [HeaderBackgroundColorScheme.White]: 'border-b border-black/20',
      [HeaderBackgroundColorScheme.Black]: 'border-b border-white/20',
      [HeaderBackgroundColorScheme.TransparentLight]: '',
      [HeaderBackgroundColorScheme.TransparentDark]: '',
    },
    hasSecondaryNav: {true: '', false: ''},
    isDesktopMenuOpen: {true: '', false: ''},
    isFixed: {true: '', false: ''},
    isMobileMenuOpen: {true: '', false: ''},
  },
  compoundVariants: [
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
      hasSecondaryNav: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
      isDesktopMenuOpen: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
      isFixed: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentLight,
      isMobileMenuOpen: true,
      className: 'border-b border-black/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
      hasSecondaryNav: true,
      className: 'border-b border-white/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
      isDesktopMenuOpen: true,
      className: 'border-b border-white/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
      isFixed: true,
      className: 'border-b border-white/20',
    },
    {
      backgroundColorScheme: HeaderBackgroundColorScheme.TransparentDark,
      isMobileMenuOpen: true,
      className: 'border-b border-white/20',
    },
  ],
});
