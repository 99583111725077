import type {SVGProps} from 'react';

// NOTE: For this icon to appear correctly in your component, you must also import and use `./GradientDefs`.

const Tech = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <path
      d="M39.5 20.25C39.5 30.8815 30.8815 39.5 20.25 39.5C9.61852 39.5 1 30.8815 1 20.25C1 9.61852 9.61852 1 20.25 1C30.8815 1 39.5 9.61852 39.5 20.25Z"
      stroke="#162120"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="path-2-inside-1_1114_69209" fill="white">
      <rect x="12" y="13.5" width="16" height="13" rx="1" />
    </mask>
    <rect
      x="12"
      y="13.5"
      width="16"
      height="13"
      rx="1"
      stroke="#162120"
      strokeWidth="3"
      mask="url(#path-2-inside-1_1114_69209)"
    />
    <path
      d="M15.6797 18L17.4811 19.8014C17.6764 19.9967 17.6764 20.3133 17.4811 20.5085L15.6797 22.31"
      stroke="#162120"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M21.4897 19H19.5V20.9897H21.4897V19Z" fill="#8EA7FF" />
    <path d="M24.4897 19H22.5V20.9897H24.4897V19Z" fill="#8EA7FF" />
  </svg>
);

export default Tech;
