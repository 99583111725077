import ButtonNav from '../ButtonNav/ButtonNav';

interface Props {
  darkBackground: boolean;
  ctaLink?: string;
}

export default function MobileCtaLinklist({darkBackground, ctaLink}: Props) {
  return (
    <li className="mr-4 text-base">
      <ButtonNav darkBackground={darkBackground} buttonLink={ctaLink} />
    </li>
  );
}
