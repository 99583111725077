import type {NavOptions} from '@/hooks/navigation/types';

import innovations from './innovations';
import support from './support';
import tools from './tools';

export default function developers(options: NavOptions) {
  const {t, gaEvent} = options;
  return {
    content: t('enterprise:nav.developers'),
    data: {
      gaEvent,
      gaAction: 'Developers',
    },
    navItems: [innovations(options), tools(options), support(options)],
  };
}
