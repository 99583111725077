import type {NavOptions} from '@/hooks/navigation/types';

import trustUs from './trustUs';
import careAbout from './careAbout';
import support from './support';

export default function resources(options: NavOptions) {
  const {t, gaEvent} = options;
  return {
    content: t('enterprise:nav.resources'),
    data: {
      gaEvent,
      gaAction: 'Resources Nav',
    },
    navItems: [trustUs(options), careAbout(options), support(options)],
  };
}
