import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function tools({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [];

  navItems.push(
    {
      content: t('enterprise:nav.developersIntegrations'),
      url: isPublishedOnSite('/developers/tools/integrations', site)
        ? localizePath('/developers/tools/integrations')
        : '/developers/tools/integrations',
      data: {
        gaEvent,
        gaAction: 'Integrations',
      },
    },
    {
      content: t('enterprise:nav.developersHydrogen'),
      url: 'https://hydrogen.shopify.dev',
      data: {
        gaEvent,
        gaAction: 'Hydrogen',
      },
    },
  );

  return {
    content: t('enterprise:nav.developersTools'),
    data: {
      gaEvent,
      gaAction: 'Tools & Integrations',
    },
    columnStart: 5,
    columnSpan: 4,
    navItems,
  };
}
