import cn from 'classnames';

import Button from '@/components/base/elements/Button/Button';
import type {ButtonProps} from '@/components/base/elements/Button/Button';
import {useTranslations} from '@/hooks/useTranslations';

interface Props {
  darkBackground: boolean;
  size?: ButtonProps['size'];
  className?: string;
  buttonLink?: string;
}

export default function ButtonNav({
  darkBackground,
  size = 'small',
  className,
  buttonLink = '#contact-sales',
}: Props) {
  const {t} = useTranslations();
  const contactText = t('global:globalNav.signUpButtonText');

  return (
    <Button
      href={buttonLink}
      size={size}
      className={cn(`${className}`, {
        'bg-white hover:bg-link-dark-hover focus:bg-link-dark-focus active:bg-link-dark-active text-link-light  hover:text-link-light focus:text-link-light active:text-link-light':
          darkBackground,
        'bg-black text-white': !darkBackground,
      })}
      componentName="contact-sales"
      intent={darkBackground ? 'primary' : 'secondary'}
    >
      {contactText}
    </Button>
  );
}
