import {helpUrl} from '@/hooks/navigation/utils';
import type {NavOptions} from '@/hooks/navigation/types';

export default function support({t, site, gaEvent}: NavOptions) {
  const navItems = [];

  navItems.push(
    {
      content: t('enterprise:nav.developersShopifyDevelopers'),
      url: 'https://shopify.dev',
      data: {
        gaEvent,
        gaAction: 'Shopify Developers',
      },
    },
    {
      content: t('enterprise:nav.developersDocumentation'),
      url: 'https://shopify.dev/docs',
      data: {
        gaEvent,
        gaAction: 'Documentation',
      },
    },
    {
      content: t('enterprise:nav.developersHelp'),
      url: helpUrl(site),
      data: {
        gaEvent,
        gaAction: 'Help Center',
      },
    },
    {
      content: t('enterprise:nav.developersChangelog'),
      url: 'https://shopify.dev/changelog',
      data: {
        gaEvent,
        gaAction: 'Changelog',
      },
    },
  );

  return {
    content: t('enterprise:nav.developersSupport'),
    data: {
      gaEvent,
      gaAction: 'Support & Resources',
    },
    columnStart: 9,
    columnSpan: 4,
    navItems,
  };
}
