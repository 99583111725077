const GradientDefs = () => (
  <svg className="sr-only" aria-hidden="true">
    <defs>
      {/* Start Icon */}
      <linearGradient
        id="start_svg__a"
        x1={4}
        y1={2.5}
        x2={37.5}
        y2={36}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00E392" />
        <stop offset={1} stopColor="#00B4CD" />
      </linearGradient>

      {/* Manage Icon */}
      <linearGradient
        id="manage_svg__a"
        x1={1}
        y1={1}
        x2={39}
        y2={39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1CD9D9" />
        <stop offset={1} stopColor="#70D50E" />
      </linearGradient>

      {/* Market Icon */}
      <linearGradient
        id="market_svg__a"
        x1={1}
        y1={1}
        x2={39}
        y2={39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EA4EF2" />
        <stop offset={1} stopColor="#29C9FF" />
      </linearGradient>

      {/* Build Icon */}
      <linearGradient
        id="start_svg__b"
        x1={4}
        y1={2.5}
        x2={37.5}
        y2={36}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00E392" />
        <stop offset={1} stopColor="#00B4CD" />
      </linearGradient>

      {/* Sell Icon */}
      <linearGradient
        id="sell_svg__a"
        x1={1}
        y1={1}
        x2={39}
        y2={39}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33EDE2" />
        <stop offset={1} stopColor="#6754FF" />
      </linearGradient>

      {/* Shared */}
      <clipPath id="white_circle_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GradientDefs;
