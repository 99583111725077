import {HeaderBackgroundColorScheme} from '@/enums';

export const getSecondaryNavTheme = (
  isScrolled: boolean,
  background = HeaderBackgroundColorScheme.TransparentLight,
) => {
  const isWhiteBackgroundWhenScrolled =
    background === HeaderBackgroundColorScheme.White ||
    (isScrolled &&
      (background === HeaderBackgroundColorScheme.TransparentLight ||
        background === HeaderBackgroundColorScheme.TransparentDark));

  return isWhiteBackgroundWhenScrolled ? 'light' : 'dark';
};
