import ButtonNav from '../ButtonNav/ButtonNav';

interface Props {
  darkBackground: boolean;
  ctaLink?: string;
}

export default function DesktopCtaLinklist({darkBackground, ctaLink}: Props) {
  return (
    <li className="leading-[0]">
      <ButtonNav darkBackground={darkBackground} buttonLink={ctaLink} />
    </li>
  );
}
