import {helpUrl} from '@/hooks/navigation/utils';
import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function support({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [
    {
      content: t('enterprise:nav.resourcesPremiumSupport'),
      url: isPublishedOnSite('/support/enterprise', site)
        ? localizePath('/support/enterprise')
        : '/support/enterprise',
      data: {
        gaEvent,
        gaAction: 'Premium Support',
      },
    },
    {
      content: t('enterprise:nav.resourcesHelp'),
      url: helpUrl(site),
      data: {
        gaEvent,
        gaAction: 'Help Documentation',
      },
    },
    {
      content: t('enterprise:nav.resourcesProfessionalServices'),
      url: isPublishedOnSite('/professional-services/enterprise', site)
        ? localizePath('/professional-services/enterprise')
        : '/professional-services/enterprise',
      data: {
        gaEvent,
        gaAction: 'Professional Services',
      },
    },
  ];

  return {
    content: t('enterprise:nav.resourcesSupport'),
    data: {
      gaEvent,
      gaAction: 'How we support you',
    },
    columnStart: 9,
    columnSpan: 4,
    navItems,
  };
}
