import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function business({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [];

  navItems.push(
    {
      content: t('enterprise:nav.solutionsB2c'),
      url: isPublishedOnSite('/solutions/b2c/enterprise', site)
        ? localizePath('/solutions/b2c/enterprise')
        : '/solutions/b2c/enterprise',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'B2C Enterprise',
      },
    },
    {
      content: t('enterprise:nav.solutionsB2b'),
      url: isPublishedOnSite('/solutions/b2b/enterprise', site)
        ? localizePath('/solutions/b2b/enterprise')
        : '/solutions/b2b/enterprise',
      data: {
        gaEvent,
        gaAction: 'B2B Enterprise',
      },
    },
    {
      content: t('enterprise:nav.solutionsRetail'),
      url: isPublishedOnSite('/solutions/retail/enterprise', site)
        ? localizePath('/solutions/retail/enterprise')
        : '/solutions/retail/enterprise',
      data: {
        gaEvent,
        gaAction: 'Retail Enterprise',
      },
    },
  );

  return {
    content: t('enterprise:nav.solutionsByBusinessModel'),
    data: {
      gaEvent,
      gaAction: 'Business Model',
    },
    columnStart: 1,
    columnSpan: 4,
    columns: 1,
    navItems,
  };
}
