import type {NavOptions} from '@/hooks/navigation/types';
import {Page} from '@/utils/server/Page';

export default function innovations({
  t,
  localizePath,
  site,
  gaEvent,
}: NavOptions) {
  const navItems = [];
  const editionsAllPage = Page.byPath('/editions/all');

  navItems.push({
    content: t('enterprise:nav.developersEditions'),
    url: editionsAllPage?.isPublishedOnSite(site)
      ? localizePath('/editions/summer2024')
      : '/editions/summer2024',
    separated: true,
    data: {
      gaEvent,
      gaAction: 'Editions - June 2024',
    },
  });

  return {
    content: t('enterprise:nav.developersInnovations'),
    data: {
      gaEvent,
      gaAction: 'Latest Innovations',
    },
    columnStart: 1,
    columnSpan: 4,
    navItems,
  };
}
