/* eslint-disable complexity */
import {useCallback, useEffect, useRef, useState} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Hamburger from '@/components/brochureV2/section/Header/Hamburger';
import type {HeaderProps} from '@/pages/shopify.com/($locale)/enterprise/components/Header/types';
import type {SecondaryNavigationType} from '@/components/base/sections/Header/modules/SecondaryNavigation/SecondaryNavigation';
import SecondaryNavigation from '@/components/base/sections/Header/modules/SecondaryNavigation/SecondaryNavigation';
import {HeaderBackgroundColorScheme, ScrollDirection} from '@/enums';
import type {NavOptions} from '@/hooks/navigation/types';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {useScrollDirection} from '@/hooks/useScrollDirection';
import {useScrollThresholds} from '@/hooks/useScrollThresholds';
import {useTranslations} from '@/hooks/useTranslations';
import type {I18NOptions} from '@/types';
import DesktopMainNavigation from '@/pages/shopify.com/($locale)/enterprise/components/Header/DesktopMainNavigation';
import solutions from '@/pages/shopify.com/($locale)/enterprise/components/Header/hooks/solutions';
import resources from '@/pages/shopify.com/($locale)/enterprise/components/Header/hooks/resources';
import developers from '@/pages/shopify.com/($locale)/enterprise/components/Header/hooks/developers';
import DesktopCtaLinklist from '@/pages/shopify.com/($locale)/enterprise/components/Header/DesktopCtaLinklist';
import MobileMainNavigation from '@/pages/shopify.com/($locale)/enterprise/components/Header/MobileMainNavigation';
import MobileCtaLinklist from '@/pages/shopify.com/($locale)/enterprise/components/Header/MobileCtaLinklist';
import {
  headerContainer2Styles,
  headerContainerStyles,
  headerStyles,
  primaryNavigationListStyles,
  secondaryNavigationContainerStyles,
} from '@/pages/shopify.com/($locale)/enterprise/components/Header/styles';
import customers from '@/pages/shopify.com/($locale)/enterprise/components/Header/hooks/сustomers';
import {getSecondaryNavTheme} from '@/pages/shopify.com/($locale)/enterprise/utils/getSecondaryNavTheme';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';

import GradientDefs from './svg/GradientDefs';

export {HeaderProps};

const defaultMainNav = ({t, localizePath, site}: I18NOptions) => {
  const gaEvent = 'Main Nav';
  const options: NavOptions = {t, localizePath, site, gaEvent};

  const navItems: (
    | ReturnType<typeof solutions>
    | ReturnType<typeof customers>
    | ReturnType<typeof resources>
    | ReturnType<typeof developers>
    | any
  )[] = [
    solutions(options),
    customers(options),
    resources(options),
    developers(options),
  ];

  return navItems;
};

interface EnterpriseHeaderProps extends HeaderProps {
  blogType?: BLOG_PAGE_TYPE;
  ctaLink?: string;
}

export default function Header({
  CustomDesktopCtaLinklist,
  CustomMobileCtaLinklist,
  CustomMobileMenuCtaLinklist,
  mainNavFn,
  secondaryNavFn,
  withBottomBar = true,
  background,
  className,
  logo: logoProp,
  navigationSearch = null,
  blogType,
  ctaLink,
}: EnterpriseHeaderProps) {
  const translations = useTranslations();
  const mainNav = mainNavFn
    ? mainNavFn(translations)
    : defaultMainNav(translations);
  const secondaryNav = secondaryNavFn
    ? ({
        ...secondaryNavFn(translations),
        moreText: translations.t('nav:more'),
      } as SecondaryNavigationType)
    : undefined;
  const hasSecondaryNav =
    (secondaryNav?.links && secondaryNav.links.length > 0) ?? false;
  const backgroundColorScheme =
    background || HeaderBackgroundColorScheme.TransparentLight;
  const lightBackground =
    background === HeaderBackgroundColorScheme.TransparentLight ||
    background === HeaderBackgroundColorScheme.White;
  const [isPrimaryNavigationInViewport, setIsPrimaryNavigationInViewport] =
    useState(true);
  const [
    activeDesktopMainNavigationIndex,
    setActiveDesktopMainNavigationIndex,
  ] = useState(-1);
  const [activeMobileMainNavigationIndex, setActiveMobileMainNavigationIndex] =
    useState(-1);
  const [isFixed, setIsFixed] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMenuOpen = activeDesktopMainNavigationIndex > -1;
  const mainHeaderRef = useRef<HTMLDivElement>(null);

  const mainHeaderInnerRef = useRef<HTMLDivElement>(null);
  const primaryNavigationOuterContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 1119px)');
  const wasMobileRef = useRef<boolean | null>(null);
  const isMenuWhiteBackground = isMenuOpen || isScrolled || isMobileMenuOpen;
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;

  const scrollDirection = useScrollDirection(hasSecondaryNav);
  const scrollThreshold = useScrollThresholds([0, 1, 71, 141]);
  // The menu should close when Escape is pressed
  useKeyPress('Escape', () => {
    setIsMobileMenuOpen(false);
    setActiveDesktopMainNavigationIndex(-1);
    setActiveMobileMainNavigationIndex(-1);
  });

  const memoizedHandleIntersectCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsPrimaryNavigationInViewport(true);
        } else {
          setIsPrimaryNavigationInViewport(false);
        }
      });
    },
    [setIsPrimaryNavigationInViewport],
  );

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  useIntersectionObserver(
    primaryNavigationOuterContainerRef,
    memoizedHandleIntersectCallback,
    options,
  );

  useEffect(() => {
    if (scrollThreshold === 0) {
      setIsFixed(false);
    } else if (hasSecondaryNav && !isPrimaryNavigationInViewport) {
      setIsFixed(true);
    } else if (!hasSecondaryNav && scrollThreshold > 0) {
      setIsFixed(true);
    }
  }, [scrollThreshold, hasSecondaryNav, isPrimaryNavigationInViewport]);

  // Once scrolled the background color of the header changes if initially transparent
  useEffect(() => {
    if (scrollThreshold > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [scrollThreshold]);

  // When transitioning between mobile and desktop breaskpoints, the menu should be closed
  useEffect(() => {
    if (isMobile === null) return;

    if (isMobile && !wasMobileRef.current) {
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    } else if (!isMobile && wasMobileRef.current) {
      setIsMobileMenuOpen(false);
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    }

    wasMobileRef.current = isMobile;
  }, [isMobile]);

  // *************************
  // **  Default Components **
  // *************************
  const logo = logoProp?.({
    isLightBackground: lightBackground,
    isOpenMenu: isMenuWhiteBackground,
  });
  const desktopCtaLinklist =
    CustomDesktopCtaLinklist === undefined ? (
      <DesktopCtaLinklist
        darkBackground={isEnterpriseBlog || !isMenuWhiteBackground}
        ctaLink={ctaLink}
      />
    ) : (
      CustomDesktopCtaLinklist?.({
        darkBackground: !isMenuWhiteBackground && !lightBackground,
      })
    );
  const mobileCtaLinklist =
    CustomMobileCtaLinklist === undefined ? (
      <MobileCtaLinklist
        darkBackground={
          (isEnterpriseBlog && !isMobileMenuOpen) || !isMenuWhiteBackground
        }
        ctaLink={ctaLink}
      />
    ) : (
      CustomMobileCtaLinklist?.({
        darkBackground: !isMenuWhiteBackground && !lightBackground,
      })
    );

  const hasHamburger =
    mainNav.length > 0 || CustomMobileMenuCtaLinklist !== null;

  return (
    <header
      ref={mainHeaderRef}
      className={twMerge(
        headerStyles({isFixed, hasSecondaryNav}),
        className,
        !isMenuWhiteBackground ? 'blog-transparent-dark-bg' : '',
      )}
      data-component-name="global-nav"
      data-has-secondary-navigation={hasSecondaryNav}
    >
      <div
        ref={mainHeaderInnerRef}
        className={twMerge(
          headerContainerStyles({
            backgroundColorScheme,
            hasSecondaryNav,
            isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
            isFixed,
            isMobileMenuOpen,
            isScrolled,
            isThresholdPassed: scrollThreshold < 72,
            scrollDirection,
            isEnterpriseBlog,
          }),
        )}
      >
        <div
          className={twMerge(
            headerContainer2Styles({
              backgroundColorScheme,
              hasSecondaryNav,
              isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
              isFixed,
              isMobileMenuOpen,
            }),
          )}
        >
          <div
            ref={primaryNavigationOuterContainerRef}
            className="h-global-header container flex items-center"
          >
            {logo}
            <DesktopMainNavigation
              mainNav={mainNav}
              openIndex={activeDesktopMainNavigationIndex}
              setOpenIndex={setActiveDesktopMainNavigationIndex}
              isScrolled={isScrolled}
              background={backgroundColorScheme}
              withBottomBar={withBottomBar}
            />
            <div className="ml-auto">
              {Boolean(desktopCtaLinklist) && (
                <ul className="lg:flex hidden items-center">
                  {desktopCtaLinklist}
                </ul>
              )}
              <ul
                className={twMerge(primaryNavigationListStyles({hasHamburger}))}
              >
                {mobileCtaLinklist}
                {hasHamburger && (
                  <li>
                    <Hamburger
                      background={
                        isMenuWhiteBackground
                          ? HeaderBackgroundColorScheme.TransparentLight
                          : backgroundColorScheme
                      }
                      isActive={isMobileMenuOpen}
                      onToggle={() => {
                        setIsMobileMenuOpen(!isMobileMenuOpen);
                        setActiveDesktopMainNavigationIndex(-1);
                        setActiveMobileMainNavigationIndex(-1);
                      }}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {hasSecondaryNav && (
          <div
            className={twMerge(
              secondaryNavigationContainerStyles({
                backgroundColorScheme,
                hasSecondaryNav,
                isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
                isFixed,
                isMobileMenuOpen,
              }),
            )}
          >
            <div className="h-global-header container flex items-center">
              <SecondaryNavigation
                secondaryNav={secondaryNav}
                mode={getSecondaryNavTheme(isMenuWhiteBackground, background)}
                showGlobals={
                  isScrolled &&
                  isFixed &&
                  scrollDirection === ScrollDirection.Down
                }
                navigationSearch={navigationSearch}
              />
            </div>
          </div>
        )}
      </div>
      <MobileMainNavigation
        logo={logo as JSX.Element}
        mobileCtaLinklist={mobileCtaLinklist}
        CustomMobileMenuCtaLinklist={CustomMobileMenuCtaLinklist}
        openIndex={activeMobileMainNavigationIndex}
        setOpenIndex={setActiveMobileMainNavigationIndex}
        isMobileMenuOpen={isMobileMenuOpen}
        background={HeaderBackgroundColorScheme.TransparentLight}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        setActiveDesktopMainNavigationIndex={
          setActiveDesktopMainNavigationIndex
        }
        setActiveMobileMainNavigationIndex={setActiveMobileMainNavigationIndex}
        withBottomBar={withBottomBar}
        mainNav={mainNav}
        ctaLink={ctaLink}
      />
      <GradientDefs />
    </header>
  );
}
