import {useRef} from 'react';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import {HeaderBackgroundColorScheme} from '@/enums';
import Anchor from '@/components/shared/Anchor/Anchor';
import Col from '@/components/brochureV2/layout/Grid/Col';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import {
  getSpanByColumnSpan,
  getStartByColumnStart,
} from '@/components/brochureV2/section/Header/utils';
import {useTranslations} from '@/hooks/useTranslations';

import {getIcon} from './svg/getIcon';
import type {
  DesktopPanelProps,
  SubSubNavItemProps,
  BottomBarProps,
} from './types';
import bottomBarItems from './hooks/bottomBar';

function SubSubNavItem({
  subSubNavItem,
  subNavItem,
  darkBackground,
  lightBackground,
}: SubSubNavItemProps) {
  const listItemRef = useRef<HTMLLIElement | null>(null);

  const handleFocus = () => {
    if (listItemRef.current) {
      listItemRef.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  return (
    <li
      className={cn('flex mb-6 pr-4 break-inside-avoid', {
        [`border-r border-shade-30 last:border-0 ${getSpanByColumnSpan(
          subSubNavItem.columnSpan ?? 3,
        )}`]:
          (subNavItem.aside || subNavItem.simple) &&
          subNavItem.columnSpan === 12,
      })}
      ref={listItemRef}
    >
      {subNavItem.simple && subSubNavItem.icon && getIcon(subSubNavItem.icon)}
      <Anchor
        className={cn('group inline-block w-full', {
          'text-white hover:text-white': darkBackground,
          'text-black hover:text-black': !darkBackground,
        })}
        href={subSubNavItem.url ?? ''}
        onFocus={handleFocus}
      >
        <div className="text-base font-medium">
          <span className={cn('inline-block arrow-animation')}>
            {subSubNavItem.content}
          </span>
        </div>
        {subSubNavItem.description && (
          <div
            className={cn(
              'text-sm',
              {
                'text-shade-70 group-hover:text-black': lightBackground,
              },
              {
                'text-shade-30 group-hover:text-white': darkBackground,
              },
            )}
          >
            {subSubNavItem.description}
          </div>
        )}
      </Anchor>
    </li>
  );
}

function BottomBar({
  background,
  openIndex,
  navItem,
  navItemIndex,
  prefersReducedMotion,
}: BottomBarProps) {
  const darkBackground =
    background === HeaderBackgroundColorScheme.Black ||
    background === HeaderBackgroundColorScheme.TransparentDark;
  const lightBackground =
    background === HeaderBackgroundColorScheme.White ||
    background === HeaderBackgroundColorScheme.TransparentLight;
  return (
    <div className="absolute bottom-0 inset-x-0 overflow-hidden">
      <Grid>
        {navItem?.navItems?.map((subNavItem, idx) => (
          <Col
            key={`${subNavItem.content || 'content'}${idx}`}
            data-background={background}
            start={
              subNavItem.columnStart
                ? getStartByColumnStart(subNavItem.columnStart)
                : undefined
            }
            span={
              subNavItem.columnSpan
                ? getSpanByColumnSpan(subNavItem.columnSpan)
                : undefined
            }
            className={twMerge(
              cn(
                {
                  'pt-8': !(subNavItem.aside && subNavItem.columnSpan === 12),
                },
                {
                  'scheme-white:bg-shade-10 scheme-transparent-light:bg-shade-10 scheme-black:bg-shade-90 scheme-transparent-dark:bg-shade-90 pl-10 relative after:absolute after:left-full after:top-[-1px] after:w-[100vw] after:h-[calc(100%+1px)] scheme-white:after:bg-shade-10 scheme-transparent-light:after:bg-shade-10 scheme-black:after:bg-shade-90 scheme-transparent-dark:after:bg-shade-90 scheme-black:text-white scheme-transparent-dark:text-white':
                    subNavItem.aside,
                },
                {
                  "-ml-[var(--margin)] -mt-8 md:mt-0 pl-[var(--margin)] pt-6 before:content-[''] before:absolute before:right-full before:top-[-1px] before:w-[100vw] before:h-[calc(100%+1px)] scheme-white:before:bg-shade-10 scheme-transparent-light:before:bg-shade-10 scheme-black:before:bg-shade-90 scheme-transparent-dark:before:bg-shade-90 border-t scheme-white:border-shade-20 scheme-transparent-light:border-shade-20 scheme-black:border-shade-70 scheme-transparent-dark:border-shade-70 before:border-t scheme-white:before:border-shade-20 scheme-transparent-light:before:border-shade-20 scheme-black:before:border-shade-70 scheme-transparent-dark:before:border-shade-70 after:border-t scheme-white:after:border-shade-20 scheme-transparent-light:after:border-shade-20 scheme-black:after:border-shade-70 scheme-transparent-dark:after:border-shade-70":
                    subNavItem.aside && subNavItem.columnSpan === 12,
                },
                {
                  'border-l scheme-white:border-shade-20 scheme-transparent-light:border-shade-20 scheme-black:border-shade-70 scheme-transparent-dark:border-shade-70':
                    subNavItem.aside && subNavItem.columnSpan !== 12,
                },
                {
                  'text-black': subNavItem.aside && darkBackground,
                  'text-white': !subNavItem.aside && darkBackground,
                },
                {
                  '-translate-y-[30px]':
                    openIndex === -1 && !prefersReducedMotion,
                },
                {
                  'translate-y-[30px]':
                    openIndex > -1 &&
                    navItemIndex !== openIndex &&
                    !prefersReducedMotion,
                },
                {
                  'translate-y-0':
                    navItemIndex === openIndex && !prefersReducedMotion,
                },
              ),
            )}
          >
            {subNavItem.content && (
              <div
                className={cn(
                  'flex items-center border-shade-30 mb-6 border-b pb-4 text-lg font-bold',
                  {
                    'border-shade-70': darkBackground,
                  },
                )}
              >
                {subNavItem.icon && getIcon(subNavItem.icon)}
                <span
                  id={`subNavItem-${subNavItem.content}`}
                  className={cn('font-bold text-t7 inline-block', {
                    'text-white': darkBackground,
                  })}
                >
                  {subNavItem.content}
                </span>
              </div>
            )}
            <ul
              className={cn({
                'pb-2': subNavItem.columnSpan !== 12,
                'columns-2': subNavItem.splitColumns,
                'columns-3': subNavItem.columns === 3,
                'grid grid-cols-12 gap-x-gutter gap-y-0 pb-0':
                  (subNavItem.aside || subNavItem.simple) &&
                  subNavItem.columnSpan === 12,
              })}
              aria-labelledby={
                subNavItem.content ? `subNavItem-${subNavItem.content}` : ''
              }
            >
              {subNavItem.navItems?.map((subSubNavItem) => (
                <SubSubNavItem
                  key={subSubNavItem.content}
                  {...{
                    subSubNavItem,
                    subNavItem,
                    darkBackground,
                    lightBackground,
                  }}
                />
              ))}
            </ul>
          </Col>
        ))}
      </Grid>
    </div>
  );
}

function DesktopPanel({
  background,
  openIndex,
  navItem,
  navItemIndex,
  prefersReducedMotion,
  prevOpenIndex,
  subNavRefs,
  withBottomBar,
}: DesktopPanelProps) {
  const translations = useTranslations();
  const darkBackground =
    background === HeaderBackgroundColorScheme.Black ||
    background === HeaderBackgroundColorScheme.TransparentDark;
  const lightBackground =
    background === HeaderBackgroundColorScheme.White ||
    background === HeaderBackgroundColorScheme.TransparentLight;

  const bottomBarProps = {
    background: HeaderBackgroundColorScheme.White,
    openIndex,
    navItem: bottomBarItems(translations),
    navItemIndex,
    prefersReducedMotion,
  };
  return (
    <div
      ref={subNavRefs.current[navItemIndex]}
      id={
        navItem.content
          ? `${navItem.content}DesktopMenu`
          : `DesktopMenu${navItemIndex}`
      }
      className={cn(
        'absolute inset-x-0 top-[73px] z-[21] pointer-events-none',
        'transition-transform duration-[452ms] ease-[cubic-bezier(0.26,1.00,0.48,1.00)] motion-reduce:transition-none',
        {
          'transition-[opacity] duration-[277ms]': prevOpenIndex.current > -1,
        },
        {
          'bg-white text-black': lightBackground,
        },
        {
          'bg-black text-white': darkBackground,
          'bg-white text-black': lightBackground,
        },
        {'opacity-0 invisible': navItemIndex !== openIndex},
        {
          'opacity-100 visible pointer-events-auto': navItemIndex === openIndex,
        },
        {
          '-translate-y-[10px]': openIndex === -1 && !prefersReducedMotion,
        },
        {
          'translate-y-0': navItemIndex === openIndex && !prefersReducedMotion,
        },
      )}
    >
      <div
        className={cn('no-scrollbar overflow-y-auto max-h-[calc(100vh-72px)]', {
          'pb-28': withBottomBar,
        })}
      >
        <Grid>
          {navItem.navItems
            ?.filter(
              (subNavItem) =>
                !(subNavItem.aside && subNavItem.columnSpan === 12),
            )
            .map((subNavItem, idx) => (
              <Col
                key={`${subNavItem.content || 'content'}${idx}`}
                data-background={background}
                start={
                  subNavItem.columnStart
                    ? getStartByColumnStart(subNavItem.columnStart)
                    : undefined
                }
                span={
                  subNavItem.columnSpan
                    ? getSpanByColumnSpan(subNavItem.columnSpan)
                    : undefined
                }
                className={twMerge(
                  cn(
                    'pt-8',
                    {
                      'scheme-white:bg-shade-10 scheme-transparent-light:bg-shade-10 scheme-black:bg-shade-90 scheme-transparent-dark:bg-shade-90 pl-10 relative after:absolute after:left-full after:top-[-1px] after:w-[100vw] after:h-[calc(100%+1px)] scheme-white:after:bg-shade-10 scheme-transparent-light:after:bg-shade-10 scheme-black:after:bg-shade-90 scheme-transparent-dark:after:bg-shade-90 scheme-black:text-white scheme-transparent-dark:text-white':
                        subNavItem.aside,
                    },
                    {
                      'border-l scheme-white:border-shade-20 scheme-transparent-light:border-shade-20 scheme-black:border-shade-70 scheme-transparent-dark:border-shade-70':
                        subNavItem.aside && subNavItem.columnSpan !== 12,
                    },
                    {
                      'text-black': subNavItem.aside && darkBackground,
                      'text-white': !subNavItem.aside && darkBackground,
                    },
                    {
                      '-translate-y-[30px]':
                        openIndex === -1 && !prefersReducedMotion,
                    },
                    {
                      'translate-y-[30px]':
                        openIndex > -1 &&
                        navItemIndex !== openIndex &&
                        !prefersReducedMotion,
                    },
                    {
                      'translate-y-0':
                        navItemIndex === openIndex && !prefersReducedMotion,
                    },
                  ),
                )}
              >
                {subNavItem.content && (
                  <div
                    className={cn(
                      'flex items-center border-shade-30 mb-6 border-b pb-4 text-lg font-bold',
                      {
                        'border-shade-70': darkBackground,
                      },
                    )}
                  >
                    {subNavItem.icon && getIcon(subNavItem.icon)}
                    <span
                      id={`subNavItem-${subNavItem.content}`}
                      className={cn('font-bold text-t7 inline-block', {
                        'text-white': darkBackground,
                      })}
                    >
                      {subNavItem.content}
                    </span>
                  </div>
                )}
                <ul
                  className={cn({
                    'pb-2': subNavItem.columnSpan !== 12,
                    'columns-2': subNavItem.splitColumns,
                    'columns-3': subNavItem.columns === 3,
                    'grid grid-cols-12 gap-x-gutter gap-y-0 pb-0':
                      (subNavItem.aside || subNavItem.simple) &&
                      subNavItem.columnSpan === 12,
                  })}
                  aria-labelledby={
                    subNavItem.content ? `subNavItem-${subNavItem.content}` : ''
                  }
                >
                  {subNavItem.navItems?.map((subSubNavItem) => (
                    <SubSubNavItem
                      key={subSubNavItem.content}
                      {...{
                        subSubNavItem,
                        subNavItem,
                        darkBackground,
                        lightBackground,
                      }}
                    />
                  ))}
                </ul>
              </Col>
            ))}
        </Grid>
      </div>
      {withBottomBar && <BottomBar {...bottomBarProps} />}
    </div>
  );
}

export default DesktopPanel;
