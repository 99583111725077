import type {NavOptions} from '@/hooks/navigation/types';

import bottomBar from './bottomBar';

export default function bottomBarItems(options: NavOptions) {
  const {gaEvent} = options;
  return {
    data: {
      gaEvent,
      gaAction: 'Bottom Bar',
    },
    navItems: [bottomBar(options)],
  };
}
