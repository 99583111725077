import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function careAbout({
  t,
  localizePath,
  site,
  gaEvent,
}: NavOptions) {
  const navItems = [
    {
      content: t('enterprise:nav.resourcesShpfCheckout'),
      url: isPublishedOnSite(
        '/commerce-components/resources/shopify-checkout-whitepaper',
        site,
      )
        ? localizePath(
            '/commerce-components/resources/shopify-checkout-whitepaper',
          )
        : '/commerce-components/resources/shopify-checkout-whitepaper',
      data: {
        gaEvent,
        gaAction: 'Shopify checkout whitepapper',
      },
    },
    {
      content: t('enterprise:nav.resourcesModernCommerce'),
      url: isPublishedOnSite(
        '/commerce-components/resources/modern-commerce-os',
        site,
      )
        ? localizePath('/commerce-components/resources/modern-commerce-os')
        : '/commerce-components/resources/modern-commerce-os',
      data: {
        gaEvent,
        gaAction: 'Modern Commerce OS',
      },
    },
  ];

  if (site.locale === 'en') {
    navItems.splice(1, 0, {
      content: t('enterprise:nav.resourcesTCOCalculator'),
      url: localizePath('/compare/tco'),
      data: {
        gaEvent,
        gaAction: 'Shopify TCO Calculator',
      },
    });
  }

  return {
    content: t('enterprise:nav.resourcesCare'),
    data: {
      gaEvent,
      gaAction: 'What we care about',
    },
    columnStart: 5,
    columnSpan: 4,
    navItems,
  };
}
