import type {NavOptions} from '@/hooks/navigation/types';

export default function bottomBar({t, localizePath, gaEvent}: NavOptions) {
  const navItems = [
    {
      content: t('global:globalNav.sharedBottomBar.shopify'),
      url: localizePath('/'),
      data: {
        gaEvent,
        gaAction: 'Shopify Homepage',
      },
      description: t('global:globalNav.sharedBottomBar.shopifyDesc'),
      columnSpan: 4,
    },
    {
      content: t('global:globalNav.sharedBottomBar.plus'),
      url: localizePath('/plus'),
      data: {
        gaEvent,
        gaAction: 'Shopify Plus',
      },
      description: t('global:globalNav.sharedBottomBar.plusDesc'),
      columnSpan: 4,
    },
    {
      content: t('global:globalNav.sharedBottomBar.enterprise'),
      url: localizePath('/enterprise'),
      data: {
        gaEvent,
        gaAction: 'Shopify Enterprise',
      },
      description: t('global:globalNav.sharedBottomBar.enterpriseDesc'),
      columnSpan: 4,
    },
  ];

  return {
    aside: true,
    columnStart: 1,
    columnSpan: 12,
    navItems,
  };
}
