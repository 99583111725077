import useGlobalNav from '@/hooks/navigation/global';
import Button from '@/components/base/elements/Button/Button';

import ButtonNav from '../ButtonNav/ButtonNav';

interface Props {
  darkBackground: boolean;
  ctaLink?: string;
}

export default function MobileMenuCtaLinklist({
  darkBackground,
  ctaLink,
}: Props) {
  const {loginText, loginUrl} = useGlobalNav();
  return (
    <>
      <li className="py-1 sm:grow sm:pt-2">
        <Button
          href={loginUrl}
          intent="secondary"
          className="w-full text-black border-black"
          mode="light"
          componentName="login"
          size="small"
        >
          {loginText}
        </Button>
      </li>
      <li className="pt-1 sm:grow sm:pt-2">
        <ButtonNav
          darkBackground={darkBackground}
          className="w-full"
          buttonLink={ctaLink}
        />
      </li>
    </>
  );
}
