import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function trustUs({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [
    {
      content: t('enterprise:nav.resourcesForresterWave'),
      url: isPublishedOnSite('/resource/forrester-wave-b2b', site)
        ? localizePath('/resource/forrester-wave-b2b')
        : '/resource/forrester-wave-b2b',
      data: {
        gaEvent,
        gaAction: 'Leader in the 2024 Forrester Wave™',
      },
    },
    {
      content: t('enterprise:nav.resourcesARGartnerMagic'),
      url: isPublishedOnSite(
        '/commerce-components/resources/analyst-report/gartner-magic-quadrant',
        site,
      )
        ? localizePath(
            '/commerce-components/resources/analyst-report/gartner-magic-quadrant',
          )
        : '/commerce-components/resources/analyst-report/gartner-magic-quadrant',
      data: {
        gaEvent,
        gaAction: 'Gartner Magic Quandrant',
      },
    },
    {
      content: t('enterprise:nav.resourcesIdcMarketscapeReport'),
      url: isPublishedOnSite('/resources/idc-marketscape-report', site)
        ? localizePath('/resources/idc-marketscape-report')
        : '/resources/idc-marketscape-report',
      data: {
        gaEvent,
        gaAction: 'IDC MarketScape Report',
      },
    },
  ];

  return {
    content: t('enterprise:nav.resourcesWhyTrustUs'),
    data: {
      gaEvent,
      gaAction: 'Why trust us',
    },
    columnStart: 1,
    columnSpan: 4,
    navItems,
  };
}
