import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function stories({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [];

  navItems.push(
    {
      content: t('enterprise:nav.customerEverlane'),
      url: isPublishedOnSite('/plus/customers/everlane', site)
        ? localizePath('/plus/customers/everlane')
        : '/plus/customers/everlane',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Everlane',
      },
      description: t('enterprise:nav.customerEverlaneDesc'),
    },
    {
      content: t('enterprise:nav.customerBrooklinen'),
      url: isPublishedOnSite('/plus/customers/brooklinen', site)
        ? localizePath('/plus/customers/brooklinen')
        : '/plus/customers/brooklinen',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Brooklinen',
      },
      description: t('enterprise:nav.customerBrooklinenDesc'),
    },
    {
      content: t('enterprise:nav.customerButcherBox'),
      url: isPublishedOnSite('/news/headless-butcherbox', site)
        ? localizePath('/news/headless-butcherbox')
        : '/news/headless-butcherbox',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'ButcherBox',
      },
      description: t('enterprise:nav.customerButcherBoxDesc'),
    },
    {
      content: t('enterprise:nav.customerArhaus'),
      url: isPublishedOnSite('/plus/customers/arhaus', site)
        ? localizePath('/plus/customers/arhaus')
        : '/plus/customers/arhaus',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Arhaus',
      },
      description: t('enterprise:nav.customerArhausDesc'),
    },
    {
      content: t('enterprise:nav.customerRuggable'),
      url: isPublishedOnSite('/plus/customers/ruggable', site)
        ? localizePath('/plus/customers/ruggable')
        : '/plus/customers/ruggable',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Ruggable',
      },
      description: t('enterprise:nav.customerRuggableDesc'),
    },
    {
      content: t('enterprise:nav.customerLuluNGeorgia'),
      url: isPublishedOnSite('/plus/customers/lulu-and-georgia', site)
        ? localizePath('/plus/customers/lulu-and-georgia')
        : '/plus/customers/lulu-and-georgia',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Lulu & Georgia',
      },
      description: t('enterprise:nav.customerLuluNGeorgiaDesc'),
    },
    {
      content: t('enterprise:nav.customerDollarShaveClub'),
      url: isPublishedOnSite('/plus/customers/dollar-shave-club', site)
        ? localizePath('/plus/customers/dollar-shave-club')
        : '/plus/customers/dollar-shave-club',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Dollar Shave Club',
      },
      description: t('enterprise:nav.customerDollarShaveClubDesc'),
    },
    {
      content: t('enterprise:nav.customerLull'),
      url: isPublishedOnSite('/plus/customers/lull', site)
        ? localizePath('/plus/customers/lull')
        : '/plus/customers/lull',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Lull',
      },
      description: t('enterprise:nav.customerLullDesc'),
    },
    {
      content: t('enterprise:nav.customerAllbirds'),
      url: isPublishedOnSite('/plus/customers/allbirds', site)
        ? localizePath('/plus/customers/allbirds')
        : '/plus/customers/allbirds',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Allbirds',
      },
      description: t('enterprise:nav.customerAllbirdsDesc'),
    },
  );

  // In case if we don`t have any items we return
  // just params for correct block bluilding and remove titles
  if (navItems.length === 0) {
    return {
      columnStart: 1,
      columnSpan: 12,
    };
  }

  return {
    content: t('enterprise:nav.customersStories'),
    data: {
      gaEvent,
      gaAction: 'Customer Stories',
    },
    columnStart: 1,
    columnSpan: 12,
    columns: 3,
    navItems,
  };
}
