import type {NavOptions} from '@/hooks/navigation/types';

import business from './business';
import platform from './platform';

export default function solutions(options: NavOptions) {
  const {t, gaEvent} = options;
  return {
    content: t('enterprise:nav.solutions'),
    data: {
      gaEvent,
      gaAction: 'Online Solutions',
    },
    navItems: [business(options), platform(options)],
  };
}
