import type {NavOptions} from '@/hooks/navigation/types';

import stories from './stories';

export default function customers(options: NavOptions) {
  const {t, gaEvent} = options;
  return {
    content: t('enterprise:nav.customers'),
    data: {
      gaEvent,
      gaAction: 'Customers',
    },
    navItems: [stories(options)],
  };
}
