import type {SVGProps} from 'react';

// NOTE: For this icon to appear correctly in your component, you must also import and use `./GradientDefs`.

const Service = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <path
      d="M39.5 20.25C39.5 30.8815 30.8815 39.5 20.25 39.5C9.61852 39.5 1 30.8815 1 20.25C1 9.61852 9.61852 1 20.25 1C30.8815 1 39.5 9.61852 39.5 20.25Z"
      stroke="#162120"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="path-2-inside-1_1114_69198" fill="white">
      <rect x="12" y="13.5" width="16" height="13" rx="1" />
    </mask>
    <rect
      x="12"
      y="13.5"
      width="16"
      height="13"
      rx="1"
      stroke="#162120"
      strokeWidth="3"
      mask="url(#path-2-inside-1_1114_69198)"
    />
    <path d="M22.9897 17H21V18.9897H22.9897V17Z" fill="#8EA7FF" />
    <path d="M22.9897 21H21V22.9897H22.9897V21Z" fill="#8EA7FF" />
    <path d="M18.9897 21H17V22.9897H18.9897V21Z" fill="#8EA7FF" />
  </svg>
);

export default Service;
