import cn from 'classnames';
import {createRef, useEffect, useRef} from 'react';

import Caret from '@/components/brochureV2/section/Header/Caret';
import Anchor from '@/components/shared/Anchor/Anchor';
import {HeaderBackgroundColorScheme} from '@/enums';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import useReducedMotion from '@/hooks/useReducedMotion';
import {useTranslations} from '@/hooks/useTranslations';
import DesktopPanel from '@/pages/shopify.com/($locale)/enterprise/components/Header/DesktopPanel';

import type {DesktopMainNavigationProps} from './types';

export default function DesktopMainNavigation({
  openIndex,
  setOpenIndex,
  isScrolled,
  background,
  mainNav,
  withBottomBar,
}: DesktopMainNavigationProps) {
  const {t} = useTranslations();
  const prefersReducedMotion = useReducedMotion(false);
  const prevOpenIndex = useRef(-1);
  const isMenuOpen = openIndex > -1;
  const subNavActivatorRefs: any = useRef<null | HTMLButtonElement>();
  subNavActivatorRefs.current = mainNav.map(
    (_: any, i: number) =>
      subNavActivatorRefs?.current?.[i] ??
      createRef<null | HTMLButtonElement>(),
  ) ?? [createRef<null | HTMLButtonElement>()];

  const subNavRefs: any = useRef<null | HTMLDivElement>();
  subNavRefs.current = mainNav.map(
    (_: any, i: number) =>
      subNavRefs?.current?.[i] ?? createRef<null | HTMLDivElement>(),
  ) ?? [createRef<null | HTMLDivElement>()];
  const lightBackground =
    background === HeaderBackgroundColorScheme.White ||
    background === HeaderBackgroundColorScheme.TransparentLight;

  const isDarkBackground = !lightBackground && !isMenuOpen && !isScrolled;
  const isLightBackground = lightBackground || isMenuOpen || isScrolled;
  const closeNavigationHandler = () => {
    setOpenIndex(-1);
    subNavActivatorRefs?.current[openIndex]?.current.focus();
  };

  useKeyPress('Escape', () => openIndex > -1 && closeNavigationHandler());
  useOnClickOutside(closeNavigationHandler);

  useEffect(() => {
    prevOpenIndex.current = openIndex;

    subNavRefs.current.forEach((subNav: any) => {
      if (subNav.current) subNav.current.scrollTop = 0;
    });
  }, [openIndex]);

  return (
    <nav
      className="lg:flex hidden h-full"
      aria-label={t('global:ariaLabels.header.mainNav')}
      itemScope
      itemType="https://schema.org/SiteNavigationElement"
      data-click-outside="dismiss"
    >
      {mainNav.length > 0 && (
        <ul className="flex h-full">
          {mainNav.map((navItem, navItemIndex: number) => (
            <li key={navItem.content} className="mr-8 text-base">
              {navItem.url ? (
                <div className="flex h-full">
                  <Anchor
                    className={cn('flex items-center hover:underline', {
                      'text-black hover:text-black': isLightBackground,
                      'text-white hover:text-white': isDarkBackground,
                    })}
                    href={navItem.url}
                  >
                    {navItem.content}
                  </Anchor>
                </div>
              ) : (
                <>
                  <div
                    className={cn(
                      [
                        'relative',
                        'flex',
                        'h-full',
                        'after:content[""]',
                        'after:block',
                        'after:h-[3px]',
                        'after:w-full',
                        'after:absolute',
                        'after:bottom-0',
                        'after:scale-0',
                        'after:origin-left',
                        'after:transition-transform',
                        'after:duration-[350ms]',
                        'after:ease-[bezier(0.66, 0.66, 0.34, 1.00)]',
                        'after:motion-reduce:transition-none',
                      ],
                      {
                        'after:scale-100': navItemIndex === openIndex,
                      },
                      {
                        'hover:underline': navItemIndex !== openIndex,
                      },
                      {
                        'after:bg-black text-black': isLightBackground,
                      },
                      {
                        'after:bg-white text-white': isDarkBackground,
                      },
                    )}
                  >
                    <button
                      ref={subNavActivatorRefs.current[navItemIndex]}
                      type="button"
                      className="bg-transparent"
                      onClick={() =>
                        navItemIndex === openIndex
                          ? setOpenIndex(-1)
                          : setOpenIndex(navItemIndex)
                      }
                      data-component-name={`${navItem.content}-toggle-${
                        navItemIndex === openIndex ? 'close' : 'open'
                      }`}
                      aria-controls={
                        navItem.content
                          ? `${navItem.content}DesktopMenu`
                          : `DesktopMenu${navItemIndex}`
                      }
                      aria-expanded={navItemIndex === openIndex}
                    >
                      <span className="mr-3">{navItem.content}</span>
                      <Caret
                        isActive={navItemIndex === openIndex}
                        background={
                          isLightBackground
                            ? HeaderBackgroundColorScheme.White
                            : background
                        }
                      />
                    </button>
                  </div>
                  <DesktopPanel
                    background={
                      isLightBackground
                        ? HeaderBackgroundColorScheme.White
                        : background
                    }
                    openIndex={openIndex}
                    navItem={navItem}
                    navItemIndex={navItemIndex}
                    prefersReducedMotion={prefersReducedMotion}
                    prevOpenIndex={prevOpenIndex}
                    subNavRefs={subNavRefs}
                    withBottomBar={withBottomBar}
                  />
                </>
              )}
            </li>
          ))}
        </ul>
      )}
      <div
        className={cn(
          'absolute inset-x-0 top-[73px] h-0 z-20 left-0 shadow-xl pointer-events-none',
          {
            'bg-white': !isDarkBackground,
            'bg-black': isDarkBackground,
          },
        )}
        style={{
          height: `${
            subNavRefs?.current[openIndex]?.current.clientHeight | 0
          }px`,
        }}
      />
    </nav>
  );
}
