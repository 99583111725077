import type {NavOptions} from '@/hooks/navigation/types';
import {isPublishedOnSite} from '@/utils/site';

export default function platform({t, localizePath, site, gaEvent}: NavOptions) {
  const navItems = [];

  navItems.push(
    {
      content: t('enterprise:nav.solutionsPlatform'),
      url: isPublishedOnSite('/platform/enterprise', site)
        ? localizePath('/platform/enterprise')
        : '/platform/enterprise',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Enterprise Platform',
      },
    },
    {
      content: t('enterprise:nav.solutionsModularCommerce'),
      url: isPublishedOnSite('/commerce-components', site)
        ? localizePath('/commerce-components')
        : '/commerce-components',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Modular commerce',
      },
    },
    {
      content: t('enterprise:nav.solutionsShopPay'),
      url: isPublishedOnSite('/solutions/shop-pay/enterprise', site)
        ? localizePath('/solutions/shop-pay/enterprise')
        : '/solutions/shop-pay/enterprise',
      separated: true,
      data: {
        gaEvent,
        gaAction: 'Shop Pay Component',
      },
    },
  );

  return {
    content: t('enterprise:nav.solutionByBuild'),
    data: {
      gaEvent,
      gaAction: 'Platform Solutions',
    },
    columnStart: 5,
    columnSpan: 4,
    navItems,
  };
}
